import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import iconArrow from '../../images/icons/black-arrow.svg';

export default class LogInModal extends Component {
    render() {
        return (
            <Modal className="modal-log-in" show={this.props.show} onHide={() => this.props.handleClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>Sign in</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3>Students</h3>
                    <a href="https://apply.commonapp.org/login?tref=3003" className="btn btn-sm btn-blue">First-year student</a>
                    <a href="https://apply.transfer.commonapp.org/applicant-ux/?tref=3003" className="btn btn-sm btn-blue">Transfer student</a>
                    <p>Don't have an account yet?</p>
                    <a href="https://apply.commonapp.org/createaccount" className="link-arrow text-black">Create an account <img src={iconArrow} alt="icon arrow" /></a>
                </Modal.Body>
                <Modal.Footer>
                    <a href="https://recommend.commonapp.org/login" className="link-arrow text-black">Recommender login <img src={iconArrow} alt="icon arrow" /></a>
                    <a href="https://members.commonapp.org/login" className="link-arrow text-black">Member college login <img src={iconArrow} alt="icon arrow" /></a>
                </Modal.Footer>
            </Modal>
        );
    }
}
